import React, { useState } from 'react';
import { Table, Input, Button, Space, ConfigProvider } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import  './../css/playertable.css'; // Import shared styles

function QbTable({ data }) {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = data.filter((player) =>
      player.last_name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText('');
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText('');
    setFilteredData(data);
  };

  const getUniqueValues = (data, key) => {
    if (!data || data.length === 0) {
      return []; // Return an empty array if data is null or empty
    }
    return [...new Set(data.map((item) => item[key]))].map((value) => ({ text: value, value }));
  };
  

  const columns = [
    {
      title: 'Player Name',
      key: 'name',
      fixed: 'left',
      render: (text, record) => (
        <span className="nowrap-tier">
          {`${record.first_name.charAt(0)}. ${record.last_name}`}
        </span>
      ),
      width: '130px',
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      filters: getUniqueValues(filteredData, 'team'),
      filteredValue: filteredInfo.team || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />
      ),
      onFilter: (value, record) => record.team.includes(value),
      sorter: (a, b) => a.team.localeCompare(b.team),
      sortOrder: sortedInfo.columnKey === 'team' && sortedInfo.order,
      width: '90px',
    },
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      sorter: (a, b) => b.ppg - a.ppg, // Default descending order
      sortOrder: sortedInfo.columnKey === 'ppg' && sortedInfo.order,
      defaultSortOrder: 'descend', // Default sort is descending
      width: '90px',
    },
    {
      title: 'PPPG',
      dataIndex: 'pppg',
      key: 'pppg',
      sorter: (a, b) => b.pppg - a.pppg, // Default descending
      sortOrder: sortedInfo.columnKey === 'pppg' && sortedInfo.order,
      width: '90px',
    },
    {
      title: 'RPPG',
      dataIndex: 'rppg',
      key: 'rppg',
      sorter: (a, b) => b.rppg - a.rppg, // Default descending
      sortOrder: sortedInfo.columnKey === 'rppg' && sortedInfo.order,
      width: '90px',
    },
    {
      title: 'AY/A',
      dataIndex: 'aya',
      key: 'aya',
      sorter: (a, b) => b.aya - a.aya, // Default descending
      sortOrder: sortedInfo.columnKey === 'aya' && sortedInfo.order,
      width: '90px',
    },
    {
      title: 'CARRY%',
      dataIndex: 'carrypercent',
      key: 'carrypercent',
      sorter: (a, b) => b.carrypercent - a.carrypercent,
      sortOrder: sortedInfo.columnKey === 'carrypercent' && sortedInfo.order,
      width: '100px',
    },
    {
      title: 'PA',
      dataIndex: 'passattempts',
      key: 'passattempts',
      sorter: (a, b) => b.passattempts - a.passattempts,
      sortOrder: sortedInfo.columnKey === 'passattempts' && sortedInfo.order,
      width: '80px',
    },
    {
      title: 'YPA',
      dataIndex: 'ypa',
      key: 'ypa',
      sorter: (a, b) => b.ypa - a.ypa,
      sortOrder: sortedInfo.columnKey === 'ypa' && sortedInfo.order,
      width: '90px',
    },
    {
      title: 'YPTRA',
      dataIndex: 'yptra',
      key: 'yptra',
      sorter: (a, b) => b.yptra - a.yptra,
      sortOrder: sortedInfo.columnKey === 'yptra' && sortedInfo.order,
      width: '100px',
    },
    {
      title: 'PASSTD%',
      dataIndex: 'passtdpercent',
      key: 'passtdpercent',
      sorter: (a, b) => b.passtdpercent - a.passtdpercent,
      sortOrder: sortedInfo.columnKey === 'passtdpercent' && sortedInfo.order,
      width: '100px',
    },
    {
      title: 'RUSHTD%',
      dataIndex: 'rushtdpercent',
      key: 'rushtdpercent',
      sorter: (a, b) => b.rushtdpercent - a.rushtdpercent,
      sortOrder: sortedInfo.columnKey === 'rushtdpercent' && sortedInfo.order,
      width: '100px',
    },
    {
      title: 'INT%',
      dataIndex: 'passintpercent',
      key: 'passintpercent',
      sorter: (a, b) => b.passintpercent - a.passintpercent,
      sortOrder: sortedInfo.columnKey === 'passintpercent' && sortedInfo.order,
      width: '80px',
    },
  ];

  const tableStyle = {
    width: '90%',
    margin: '0 auto',
    marginTop: '20px',
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            color: '#e91e63',
            backgroundColor: '#3f3f3f',
            borderColor: '#3f3f3f',
            headerBg: '#3f3f3f',
            headerColor: '#ffffff',
            darkItemSelectedBg: '#e91e63',
            darkItemHoverColor: '#e91e63',
            rowHoverBg: '#f83f7c',
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: '100%' }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by last name"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={pagination}
          scroll={{ y: 400 }}
          rowClassName={(record, index) =>
            `dark-mode-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`
          }
        />
      </div>
    </ConfigProvider>
  );
}

export default QbTable;
