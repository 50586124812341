import React, { useState } from 'react';
import { Table, Button, Space, ConfigProvider, Input } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import './../css/playertable.css';

const PlayerTable = ({ data, onRowClick }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [searchText, setSearchText] = useState('');

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText('');
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText('');
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map(item => item[key]))].map(value => ({ text: value, value }));
  };

  const filteredData = data.filter(item =>
    item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    item.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'Pos',
      dataIndex: 'position',
      key: 'position',
      width: '70px',
      filters: getUniqueValues(data, 'position'),
      filteredValue: filteredInfo.position || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.position.includes(value),
      sorter: (a, b) => a.position.localeCompare(b.position),
      sortOrder: sortedInfo.columnKey === 'position' && sortedInfo.order,
    },
    {
      title: 'Name',
      key: 'name',
      width: '100px',
      render: (text, record) => <span className="nowrap-tier">{`${record.first_name.charAt(0)}. ${record.last_name}`}</span>,
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      width: '90px',
      filters: getUniqueValues(data, 'team'),
      filteredValue: filteredInfo.team || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.team.includes(value),
      sorter: (a, b) => a.team.localeCompare(b.team),
      sortOrder: sortedInfo.columnKey === 'team' && sortedInfo.order,
    },
    {
      title: 'Pos Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '100px',
      sorter: (a, b) => a.rank - b.rank,
      sortOrder: sortedInfo.columnKey === 'rank' && sortedInfo.order,
    },
    {
      title: 'Player Rank',
      dataIndex: 'startuprank',
      key: 'startuprank',
      width: '100px',
      sorter: (a, b) => a.startuprank - b.startuprank,
      sortOrder: sortedInfo.columnKey === 'startuprank' && sortedInfo.order,
    },
    {
      title: 'Player Tier',
      dataIndex: 'tier',
      key: 'tier',
      width: '120px',
      filters: getUniqueValues(data, 'tier'),
      filteredValue: filteredInfo.tier || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.tier.includes(value),
      render: (text) => {
        let className = '';
        if (text === 'UNTOUCHABLE') {
          className = 'tier-UNTOUCHABLE';
        } else if (text === 'CORNERSTONE') {
          className = 'tier-CORNERSTONE';
        } else if (text === 'FOUNDATION') {
          className = 'tier-FOUNDATION';
        } else if (text === 'BASE') {
          className = 'tier-BASE';
        } else if (text === 'DEPTH') {
          className = 'tier-DEPTH';
        } else if (text === 'CLOGGER') {
          className = 'tier-CLOGGER';
        }
        return <span className={`${className} nowrap-tier`}>{text}</span>;
      },
    },
    {
      title: 'Player Type',
      dataIndex: 'type',
      key: 'type',
      width: '120px',
      filters: getUniqueValues(data, 'type'),
      filteredValue: filteredInfo.type || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.type.includes(value),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
    },
    {
      title: 'Contend Tag',
      dataIndex: 'contenderbsh',
      key: 'contenderbsh',
      width: '130px',
      filters: getUniqueValues(data, 'contenderbsh'),
      filteredValue: filteredInfo.contenderbsh || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.contenderbsh.includes(value),
      sorter: (a, b) => a.contenderbsh.localeCompare(b.contenderbsh),
      sortOrder: sortedInfo.columnKey === 'contenderbsh' && sortedInfo.order,
      render: (text) => {
        let className = '';
        if (text === 'BUY') {
          className = 'tier-BUY';
        } else if (text === 'SELL') {
          className = 'tier-SELL';
        }
        return <span className={className}>{text}</span>;
      },
    },
    {
      title: 'Rebuild Tag',
      dataIndex: 'rebuilderbsh',
      key: 'rebuilderbsh',
      width: '120px',
      filters: getUniqueValues(data, 'rebuilderbsh'),
      filteredValue: filteredInfo.rebuilderbsh || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.rebuilderbsh.includes(value),
      sorter: (a, b) => a.rebuilderbsh.localeCompare(b.rebuilderbsh),
      sortOrder: sortedInfo.columnKey === 'rebuilderbsh' && sortedInfo.order,
      render: (text) => {
        let className = '';
        if (text === 'BUY') {
          className = 'tier-BUY';
        } else if (text === 'SELL') {
          className = 'tier-SELL';
        }
        return <span className={className}>{text}</span>;
      },
    },
    {
      title: 'Draft YR',
      dataIndex: 'draftyear',
      key: 'draftyear',
      width: '100px',
      filters: getUniqueValues(data, 'draftyear'),
      filteredValue: filteredInfo.draftyear || null,
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      onFilter: (value, record) => record.draftyear.toString().includes(value),
      sorter: (a, b) => a.draftyear - b.draftyear,
      sortOrder: sortedInfo.columnKey === 'draftyear' && sortedInfo.order,
    },
    {
      title: 'College',
      dataIndex: 'college',
      key: 'college',
      width: '120px',
      sorter: (a, b) => a.college.localeCompare(b.college),
      filters: getUniqueValues(data, 'college'),
      filteredValue: filteredInfo.college || null,
      onFilter: (value, record) => record.college.toString().includes(value),
      filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
      sortOrder: sortedInfo.columnKey === 'college' && sortedInfo.order,
      render: (text) => <span className="nowrap-tier">{text}</span>,

    },
  ];

  const tableStyle = {
    width: '90%',
    margin: '0 auto',
    marginTop: '20px',
    color: '#ffffff',
  };

  const handleRowClick = (record) => {
    onRowClick(record);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            color: '#e91e63',
            backgroundColor: '#e91e63',
            borderColor: '#3f3f3f',
            headerBg: '#3f3f3f',
            headerColor: '#ffffff',
            darkItemSelectedBg: '#e91e63',
            darkItemHoverColor: '#e91e63',
            rowHoverBg: '#f83f7c',
            footerColor: '#ffffff',
            footerBg: '#ffffff'
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: '100%' }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by name"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
        <Table
          size='small'
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={pagination}
          scroll={{ y: 400 }}
          rowClassName={(record, index) => `dark-mode-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
          style={{ backgroundColor: '#3f3f3f' }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </ConfigProvider>
  );
};

export default PlayerTable;
