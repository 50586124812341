import React, { useState, useEffect } from 'react';
import { Tabs, Spin } from 'antd'; // Importing Tabs and a loading spinner
import QbTable from './qbtable';
import { fetchPositionStats } from '../services/playerservice';
import RbTable from './rbtable';
import WrTeTable from './wrtetable';

const { TabPane } = Tabs;

function PositionTabs() {
    const [activeTab, setActiveTab] = useState('qb');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadData = async (position) => {
        setLoading(true);
        const result = await fetchPositionStats(position); // Use the new function
        setData(result);
        setLoading(false);
    };

    useEffect(() => {
        loadData(activeTab);
    }, [activeTab]);

    const tabContentStyle = {
        backgroundColor: '#1e1e1e', // Example background color
        padding: '20px',           // Add padding inside the tab
        borderRadius: '8px',       // Optional: rounded corners for a smoother look
        minHeight: '400px',        // Ensure it takes up enough vertical space
    };

    return (
        <div className="position-tabs">
            <Tabs defaultActiveKey="qb" onChange={setActiveTab}>
                <TabPane tab="Quarterbacks" key="qb">
                    <div style={tabContentStyle}>
                        {loading ? <Spin /> : <QbTable data={data} />}
                    </div>
                </TabPane>
                <TabPane tab="Running Backs" key="rb">
                    <div style={tabContentStyle}>
                        {loading ? <Spin /> : <RbTable data={data} />}
                    </div>
                </TabPane>
                <TabPane tab="Wide Receivers" key="wr">
                    <div style={tabContentStyle}>
                        {loading ? <Spin /> : <WrTeTable data={data} />}
                    </div>
                </TabPane>
                <TabPane tab="Tight Ends" key="te">
                    <div style={tabContentStyle}>
                        {loading ? <Spin /> : <WrTeTable data={data} />}
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default PositionTabs;
