import React, { useState, useEffect } from 'react';
import { Select, Button, Checkbox, Tooltip, Modal } from 'antd';
import { getPlayer, getAllPlayers } from './../services/playerservice';
import ComparisonWidget from './../views/comparisonwidget';
import './../css/compare.css';
import qbs from './../assets/images/qbs.png';
import rbs from './../assets/images/rbs.png';
import wrs from './../assets/images/wrs.png';
import tes from './../assets/images/tes.png';
import { MenuOutlined, EyeFilled, AlignCenterOutlined, ArrowUpOutlined, SignalFilled, InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

function ComparePage({ players }) {
    const [firstPlayer, setFirstPlayer] = useState(null);
    const [secondPlayer, setSecondPlayer] = useState(null);
    const [firstPlayerInfo, setFirstPlayerInfo] = useState(null);
    const [secondPlayerInfo, setSecondPlayerInfo] = useState(null);
    const [includeRetired, setIncludeRetired] = useState(false);
    const [allPlayers, setAllPlayers] = useState(null);
    const [showTooltipText, setShowTooltipText] = useState(false); // Track if tooltip text should display
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleFirstPlayerChange = (value) => {
        const selectedPlayer = playersList.find(player => player.id === value);
        setFirstPlayer(selectedPlayer);
    };

    const handleSecondPlayerChange = (value) => {
        const selectedPlayer = playersList.find(player => player.id === value);
        setSecondPlayer(selectedPlayer);
    };

    useEffect(() => {
        async function fetchPlayers() {
            try {
                const data = await getAllPlayers();
                setAllPlayers(data);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        }
        fetchPlayers();
    }, []);

    const handleCompareClick = async () => {
        if (firstPlayer && secondPlayer) {
            try {
                const firstPlayerData = await getPlayer(firstPlayer.id);
                const secondPlayerData = await getPlayer(secondPlayer.id);
                setFirstPlayerInfo(firstPlayerData);
                setSecondPlayerInfo(secondPlayerData);

                // Display tooltip text after button click
                setShowTooltipText(true);
            } catch (error) {
                console.error('Error fetching comparison data:', error);
            }
        }
    };

    const sortedPlayers = (list) => {
        return [...list].sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
            const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    };

    const playersList = includeRetired && allPlayers ? sortedPlayers(allPlayers) : sortedPlayers(players);

    return (
        <div className="compare-page">
            {/* Image grid at the top */}
            <div className="image-container">
                <img src={qbs} alt="Player 1" className="grid-image" />
                <img src={rbs} alt="Player 2" className="grid-image" />
                <img src={wrs} alt="Player 3" className="grid-image" />
                <img src={tes} alt="Player 4" className="grid-image" />
            </div>

            {/* Rectangle with split sections */}
            <div className="floating-rectangle">
                <div className="section">
                    <MenuOutlined className="icon" />
                    <p className="text">CHOOSE PLAYERS</p>
                </div>
                <div className="section">
                    <EyeFilled className="icon" />
                    <p className="text">VIEW CAREER STATS</p>
                </div>
                <div className="section">
                    <AlignCenterOutlined className="icon" />
                    <p className="text">COMPARE YEARS</p>
                </div>
                <div className="section">
                    <ArrowUpOutlined className="icon" />
                    <p className="text">ANALYZE TRENDS</p>
                </div>
                <div className="section">
                    <SignalFilled className="icon" />
                    <p className="text">VIEW SIDE BY SIDE</p>
                </div>
            </div>

            <div className="compare-players-container">
                <div className="compare-players-text">
                    <h2 className="compare-players-title">COMPARE PLAYERS</h2>
                    <p className="compare-players-description">
                        Use our comparison tool to select two players and analyze their performance side by side. This tool allows you to compare statistics and gain insights into how players match up against each other.
                    </p>
                </div>

                <div className="compare-players-selection">
                    {/* First Player Dropdown */}
                    <Select
                        showSearch
                        placeholder="Select First Player"
                        style={{ width: 240, marginBottom: '10px' }}
                        onChange={handleFirstPlayerChange}
                        value={firstPlayer ? firstPlayer.id : null}
                        filterOption={(input, option) => {
                            const playerName = option.label.toLowerCase();
                            return playerName.includes(input.toLowerCase());
                        }}
                    >
                        {playersList.map((player) => (
                            <Option key={player.id} value={player.id} label={`${player.first_name} ${player.last_name}`}>
                                {player.first_name} {player.last_name}
                            </Option>
                        ))}
                    </Select>

                    {/* Second Player Dropdown */}
                    <Select
                        showSearch
                        placeholder="Select Second Player"
                        style={{ width: 240, marginBottom: '10px' }}
                        onChange={handleSecondPlayerChange}
                        value={secondPlayer ? secondPlayer.id : null}
                        filterOption={(input, option) => {
                            const playerName = option.label.toLowerCase();
                            return playerName.includes(input.toLowerCase());
                        }}
                    >
                        {playersList.map((player) => (
                            <Option key={player.id} value={player.id} label={`${player.first_name} ${player.last_name}`}>
                                {player.first_name} {player.last_name}
                            </Option>
                        ))}
                    </Select>

                    {/* Checkbox for including retired players */}
                    <Checkbox
                        checked={includeRetired}
                        onChange={(e) => setIncludeRetired(e.target.checked)}
                        style={{ marginBottom: '20px', color: '#fff' }}
                    >
                        Add in retired players
                    </Checkbox>

                    {/* Submit Button */}
                    <Button
                        className="submit-button"
                        type="primary"
                        onClick={handleCompareClick}
                        disabled={!firstPlayer || !secondPlayer}
                    >
                        Compare Players
                    </Button>
                </div>
            </div>

            {firstPlayerInfo && secondPlayerInfo && (
                <ComparisonWidget firstPlayer={firstPlayerInfo} secondPlayer={secondPlayerInfo} />
            )}

            {/* Conditional Text and Tooltip */}
            {showTooltipText && (
                <div style={{ marginTop: '20px' }}>
                    Not seeing a season?
                    <Tooltip title="Click for more info">
                        <InfoCircleOutlined
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => setIsModalVisible(true)}
                        />
                    </Tooltip>
                </div>
            )}

            {/* Modal for missing seasons or information */}
            <Modal
                title="Missing a Season?"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => setIsModalVisible(false)}
            >
                <p>If you are missing a season, it likely isn't eligible due to the number of games played. Ineligible seasons are hidden to ensure a sufficient sample size for meaningful comparisons. If the current year is missing, check back around week 7 or 8 to find it.</p>
            </Modal>
        </div>
    );
}

export default ComparePage;
