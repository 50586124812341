import React, { useState, useEffect } from 'react';
import { Table, ConfigProvider } from 'antd';
import { qb, rb, wr, te } from '../utils/tablecolumns'; // Make sure these imports are correct



const stablecolumns = [
  {
    title: '#',
    dataIndex: 'yearnum',
    key: 'yearnum',
    width: '4%',
    sorter: (a, b) => a.yearnum - b.yearnum,
    render: (text) => <span style={{ color: 'black', fontWeight: 'bold' }}>{`${text}`}</span>, // Change text color to black
    fixed: 'left', // This will make the first column sticky on the left
  },
  {
    title: 'YR',
    dataIndex: 'calendaryear',
    key: 'calendaryear',
    width: '8%',
    sorter: (a, b) => a.calendaryear - b.calendaryear,
  },
  // {
  //   title: 'TM',
  //   dataIndex: 'team',
  //   key: 'team',
  //   width: 50,
  //   sorter: (a, b) => a.team.localeCompare(b.team),
  // },
  // {
  //   title: 'GM',
  //   dataIndex: 'games',
  //   key: 'games',
  //   width: 50,
  //   sorter: (a, b) => a.games - b.games,
  // },
];

const getPositionColumns = (position) => {
  // Convert the position to lowercase to ensure case-insensitive comparison
  const lowerCasePosition = position.toLowerCase();

  switch (lowerCasePosition) {
    case 'qb':
      return qb;
    case 'rb':
      return rb;
    case 'wr':
      return wr;
    case 'te':
      return te;
    default:
      return [];
  }
};

// const getColumnLength = (position) => {
//   // Convert the position to lowercase to ensure case-insensitive comparison
//   const lowerCasePosition = position.toLowerCase();

//   switch (lowerCasePosition) {
//     case 'qb':
//       return qblength;
//     case 'rb':
//       return rblength;
//     case 'wr':
//       return wrlength;
//     case 'te':
//       return telength;
//     default:
//       return 80;
//   }
// };

const StatsTable = ({ position, stats }) => {
  const positionColumns = getPositionColumns(position);
  const columns = [...stablecolumns, ...positionColumns];

  const sortedStats = stats.sort((a, b) => a.calendaryear - b.calendaryear);


  // Dynamically calculate the table's height as 50% of the viewport height
  const [tableHeight, setTableHeight] = useState(() => window.innerHeight * 0.5); // Set initial height based on viewport

  useEffect(() => {
    setTableHeight(0)
    const handleResize = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.2); // Set the height to 50% of the viewport height
    };

    // Set the initial height
    handleResize();

    // Add event listener to adjust on window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            bodySortBg: '#696969', // Set your desired background color for sorted columns
          },
        },
      }}
    >
      <Table
        columns={columns}
        dataSource={sortedStats}
        scroll={{ x: 1100, y: tableHeight, scrollBar: true }}
        pagination={{
          position: ['none', 'none'],
        }}
        sortDirections={['descend', 'ascend']}
        rowHoverable={false}
        size="small"
        className="custom-table" // Apply custom class for styling
      />
    </ConfigProvider>
  );
};

export default StatsTable;
