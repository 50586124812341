// src/views/headercomponent.js

import React, { useState } from 'react';
import { Menu, Layout, ConfigProvider, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons'; // Import the hamburger icon
import './../css/headercomponent.css'; // Import your CSS file

const { Header } = Layout;

const HeaderComponent = ({ isMobile }) => { // Accept isMobile prop
  const location = useLocation();
  const [visible, setVisible] = useState(false); // State to control Drawer visibility

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <Header className='header'>
      <div className='centered-header'>
        {isMobile ? (
          <>
            <MenuOutlined onClick={showDrawer} style={{ fontSize: '24px', color: '#ffffff' }} /> {/* Hamburger icon */}
            <Drawer
              title="Navigation"
              placement="left"
              onClose={closeDrawer}
              visible={visible}
              key="left"
              width={240} // Set the width of the Drawer
            >
              <Menu
                mode="vertical" // Change to vertical for drawer
                selectedKeys={[location.pathname]}
                style={{ width: '100%', textAlign: 'left' }} // Style the Menu
              >
                <Menu.Item key="/"><Link to="/" onClick={closeDrawer}>HOME</Link></Menu.Item>
                <Menu.Item key="/players"><Link to="/players" onClick={closeDrawer}>PLAYERS</Link></Menu.Item>
                <Menu.Item key="/performance"><Link to="/performance" onClick={closeDrawer}>PERFORMANCE</Link></Menu.Item>
                <Menu.Item key="/compare"><Link to="/compare" onClick={closeDrawer}>COMPARE</Link></Menu.Item>
                <Menu.Item key="/similar"><Link to="/similar" onClick={closeDrawer}>SIMILARITY</Link></Menu.Item>
                <Menu.Item key="/rostership"><Link to="/rostership" onClick={closeDrawer}>ROSTERSHIP</Link></Menu.Item>
                {/* <Menu.Item key="/feedback"><Link to="/feedback" onClick={closeDrawer}>FEEDBACK</Link></Menu.Item> */}
              </Menu>
            </Drawer>
          </>
        ) : (
          <>
            <div className="logo-container">
              <img src="/images/dynastyimpng.webp" alt="Logo" className="logo" />
            </div>
            <div className="menu-container">
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      itemColor: 'transparent', // Change background color here
                      horizontalItemSelectedColor: '#ffffff', // Optional: Change text color
                      itemHoverColor: '#ffffff'
                    },
                  },
                }}
              >
                <Menu
                  mode="horizontal"
                  selectedKeys={[location.pathname]}
                  className="custom-menu"
                >
                  <Menu.Item key="/"><Link to="/">HOME</Link></Menu.Item>
                  <Menu.Item key="/players"><Link to="/players">PLAYERS</Link></Menu.Item>
                  <Menu.Item key="/performance"><Link to="/performance" onClick={closeDrawer}>PERFORMANCE</Link></Menu.Item>
                  <Menu.Item key="/compare"><Link to="/compare" onClick={closeDrawer}>COMPARE</Link></Menu.Item>
                  <Menu.Item key="/similar"><Link to="/similar" onClick={closeDrawer}>SIMILARITY</Link></Menu.Item>
                  <Menu.Item key="/rostership"><Link to="/rostership">ROSTERSHIP</Link></Menu.Item>
                  {/* <Menu.Item key="/feedback"><Link to="/feedback">FEEDBACK</Link></Menu.Item> */}
                </Menu>
              </ConfigProvider>
            </div>
          </>
        )}
      </div>
    </Header>
  );
};

export default HeaderComponent;
