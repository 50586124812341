import React, { useState } from 'react';
import { Table, Input, Space, Button, ConfigProvider } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import './../css/playertable.css'; // Use the same CSS as PlayerTable

function RbTable({ data }) {
    const [searchText, setSearchText] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });

    const handleSearch = (e) => {
        setSearchText(e.target.value.toLowerCase());
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
        setPagination(pagination);
    };

    const clearFilters = () => {
        setFilteredInfo({});
        setSearchText('');
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
        setSearchText('');
    };

    const getUniqueValues = (data, key) => {
        return [...new Set(data.map((item) => item[key]))].map((value) => ({
            text: value,
            value,
        }));
    };

    const filteredData = data
        .filter(
            (item) =>
                item.first_name.toLowerCase().includes(searchText) ||
                item.last_name.toLowerCase().includes(searchText)
        )
        .map((item) => ({
            ...item,
            key: item.id, // Ensure unique keys
        }));

    const columns = [
        {
            title: 'Name',
            key: 'name',
            width: '120px',
            fixed: 'left',
            render: (text, record) => (
                <span className="nowrap-tier" style={{ whiteSpace: 'nowrap' }}>
                    {`${record.first_name.charAt(0)}. ${record.last_name}`}
                </span>
            ),
        },
        {
            title: 'Team',
            dataIndex: 'team',
            key: 'team',
            width: '90px',
            filters: getUniqueValues(data, 'team'),
            filteredValue: filteredInfo.team || null,
            filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? '#f83f7c' : '#ffffff' }} />,
            onFilter: (value, record) => record.team.includes(value),
            sorter: (a, b) => a.team.localeCompare(b.team),
            sortOrder: sortedInfo.columnKey === 'team' && sortedInfo.order,
        },
        {
            title: 'PPG',
            dataIndex: 'ppg',
            key: 'ppg',
            width: '70px',
            sorter: (a, b) => b.ppg - a.ppg,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'ppg' && sortedInfo.order,
            className: 'columnSorted',
        },
        {
            title: 'CARRY%',
            dataIndex: 'carrypercent',
            key: 'carrypercent',
            width: '90px',
            sorter: (a, b) => b.carrypercent - a.carrypercent,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'carrypercent' && sortedInfo.order,
        },
        {
            title: 'YPTRA',
            dataIndex: 'yptra',
            key: 'yptra',
            width: '90px',
            sorter: (a, b) => b.yptra - a.yptra,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'yptra' && sortedInfo.order,
        },
        {
            title: 'RUSH TD MS',
            dataIndex: 'rushtdms',
            key: 'rushtdms',
            width: '130px',
            sorter: (a, b) => b.rushtdms - a.rushtdms,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'rushtdms' && sortedInfo.order,
        },
        {
            title: 'RUSH PPG',
            dataIndex: 'rushppg',
            key: 'rushppg',
            width: '110px',
            sorter: (a, b) => b.rushppg - a.rushppg,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'rushppg' && sortedInfo.order,
        },
        {
            title: 'REC PPG',
            dataIndex: 'receivingppg',
            key: 'receivingppg',
            width: '110px',

            sorter: (a, b) => b.receivingppg - a.receivingppg,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'receivingppg' && sortedInfo.order,
        },
        {
            title: 'TS%',
            dataIndex: 'targetshare',
            key: 'targetshare',
            width: '70px',

            sorter: (a, b) => b.targetshare - a.targetshare,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'targetshare' && sortedInfo.order,
        },
        {
            title: 'YPTPA',
            dataIndex: 'yptpa',
            key: 'yptpa',
            width: '70px',

            sorter: (a, b) => b.yptpa - a.yptpa,
            defaultSortOrder: 'descend',
            sortOrder: sortedInfo.columnKey === 'yptpa' && sortedInfo.order,
        },
    ];

    const tableStyle = {
        width: '90%',
        margin: '0 auto',
        marginTop: '20px',
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        color: '#e91e63',
                        backgroundColor: '#3f3f3f',
                        borderColor: '#3f3f3f',
                        headerBg: '#3f3f3f',
                        headerColor: '#ffffff',
                        rowHoverBg: '#f83f7c',
                    },
                },
            }}
        >
            <div style={tableStyle}>
                <Space style={{ marginBottom: 16, width: '100%' }} direction="vertical">
                    <div className="search-and-buttons">
                        <Input
                            placeholder="Search by last name"
                            value={searchText}
                            onChange={handleSearch}
                            suffix={<SearchOutlined />}
                            className="search-input"
                        />
                        <div className="button-group">
                            <Button onClick={clearFilters}>Clear Filters</Button>
                            <Button onClick={clearAll}>Clear All</Button>
                        </div>
                    </div>
                </Space>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={filteredData}
                    onChange={handleChange}
                    rowKey="id"
                    pagination={pagination}
                    scroll={{ y: 400 }}
                    rowClassName={(record, index) =>
                        `dark-mode-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`
                    }
                />
            </div>
        </ConfigProvider>
    );
}

export default RbTable;
